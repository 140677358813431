/*require('bootstrap')*/


/*require('./components/polyfill')*/

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './registerServiceWorker'

import(/* webpackChunkName: "main-style" */ /* webpackPreload: true */ "@/assets/sass/style.scss");

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
