<template>
  <div id="app">
    <Raf></Raf>
    <transition
      enter-active-class="animated bounceInLeft"
      leave-active-class="animated bounceOutRight">
      <router-view/>
    </transition>
  <button class="update" v-if="updateExists" @click="refreshApp">
    Nouvelle version disponible ! Cliquez pour mettre à jour
  </button>
</div>
</template>

<script>

  const viewport = () => import("@/assets/js/resize-content");
  const MobileDetect = require("mobile-detect");

  export default {
    data() {
      return {
        refreshing: false,
        registration: null,
        updateExists: false,
      };
    },
    components: {
      Raf: () => import("@/components/Raf.vue"),
    },
    created () {
      document.addEventListener('swUpdated', this.showRefreshUI, { once: true });
      navigator.serviceWorker.addEventListener('controllerchange', () => {
        if (this.refreshing) return;
        this.refreshing = true;
        window.location.reload();
      });
    },
    mounted() {
      viewport().then(response => {
        const md = new MobileDetect(window.navigator.userAgent);
        if (md.mobile() === null && md.phone() === null && md.tablet() === null) {
          window.onload = response.default.resizeGame();
          window.addEventListener('resize', response.default.resizeGame, false);
          window.addEventListener('orientationchange', response.default.resizeGame, false);
        } else {
          const gameArea = document.getElementById('app');
          gameArea.style.left = 0;
          gameArea.style.top = 0;
        }
      });
    },
    methods: {
      showRefreshUI (e) {
        this.registration = e.detail;
        this.updateExists = true;
      },
      refreshApp () {
        this.updateExists = false;
        if (!this.registration || !this.registration.waiting) { return; }
        this.registration.waiting.postMessage('skipWaiting');
      },
    }
  }
</script>

<style lang="scss">

html, body {
  overflow: hidden;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  font-family: 'Helvetica Neue', Helvetica, Roboto, Arial, sans-serif;
  font-size: $base-font-size*0.45;
  line-height: $base-line-height;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  text-size-adjust: 100%;
  @media #{$mq-small} {
    font-size: $base-font-size*0.55;
  }
  @media #{$mq-xm} {
    font-size: $base-font-size*0.65;
  }
  @media #{$mq-medium} {
    font-size: $base-font-size*0.7;
  }
  @media #{$mq-large} {
    font-size: $base-font-size*0.75;
  }
  @media #{$mq-xlarge} {
    font-size: $base-font-size*1.3;
  }
  @media #{$mq-xxlarge} {
    font-size: $base-font-size*1.8;
  }
  @media #{$mq-xxxlarge} {
    font-size: $base-font-size*2;
  }
  @media #{$mq-xxxxlarge} {
    font-size: $base-font-size*2.25;
  }
}

h1, h2 {
  color: var(--color-dark_red);
}

#app {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  position: absolute;
  left: 50%;
  top: 50%;
}

.text__big {
  @include fluid-size('font-size', 16px, 50px, $min_vw, $max_vw);
  font-weight: 700;
}

.bckg_dark_red {
  background-color: var(--color-dark_red) !important;
}

img, video {
  width: 100%;
}

.update {
  position: absolute;
  bottom: 20%;
  right: 16px;
  height: 35px;
  background-color: var(--color-white);
  border: none;
  box-shadow: 0 1px 2px rgba(0, 0, 0, .5);
  cursor: pointer;
  &:focus {
    border: none;
    outline: none;
  }
}

// ---------------------------------------------------------------------------
// Orientation
// ---------------------------------------------------------------------------

@media only screen 
and (orientation:portrait)
and (min-device-width: 800px) 
and (max-device-width: 1280px)
{
  #app {
    height: 100vw;
    padding: 0;
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
  }
}

@media only screen
and (min-device-width: 320px) 
and (max-device-width: 1024px)
{
  #app {
    display: none;
  }
  body {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
    &:before {
      content: 'Ne convient pas pour cette résolution';
      @include fluid-size(font-size, 16px, 25px, 320px, 1024px);
    }
  }
}

// Navigation
.navigation {
  ul {
    display: flex;
    li {
      span {
        position: relative;
        padding: .25rem;
        text-transform: uppercase;
        font-weight: 700;
        font-size: .65em;
        @media #{$mq-xm} {
          padding: .25rem .5rem;
        }
        @media #{$mq-large} {
          padding: .25rem .75rem;
        }
        @media #{$mq-xlarge} {
          padding: .25rem 1rem;
        }
        @media #{$mq-xxlarge} {
          padding: .5rem 1rem;
        }
      }
    }
  }
}

</style>
