import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
{
  path: '/viscoreducteur',
  name: 'viscoreducteur',
    // route level code-splitting
    // this generates a separate chunk (viscoreducteur.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "viscoreducteur" */ '../views/Viscoreducteur.vue'),
  },
  {
    path: '/craqueur',
    name: 'craqueur',
    component: () => import(/* webpackChunkName: "craqueur" */ '../views/Craqueur.vue'),
  },
  {
    path: '/alkylation',
    name: 'alkylation',
    component: () => import(/* webpackChunkName: "alkylation" */ '../views/Alkylation.vue'),
  },
  {
    path: '/distillation',
    name: 'distillation',
    component: () => import(/* webpackChunkName: "distillation" */ '../views/Distillation.vue'),
  },
  {
    path: '/hydrodesulfuration',
    name: 'hydrodesulfuration',
    component: () => import(/* webpackChunkName: "hydrodesulfuration" */ '../views/Hydrodesulfuration.vue'),
  },
  {
    path: '/reformeur',
    name: 'reformeur',
    component: () => import(/* webpackChunkName: "reformeur" */ '../views/Reformeur.vue'),
  },
  {
    path: '/abeilles',
    name: 'abeilles',
    component: () => import(/* webpackChunkName: "abeilles" */ '../views/popup/Abeilles.vue'),
  },
  {
    path: '/appontements',
    name: 'appontements',
    component: () => import(/* webpackChunkName: "appontements" */ '../views/popup/Appontements.vue'),
  },
  {
    path: '/astrolabe',
    name: 'astrolabe',
    component: () => import(/* webpackChunkName: "astrolable" */ '../views/popup/Astrolabe.vue'),
  },
  {
    path: '/bacs',
    name: 'bacs',
    component: () => import(/* webpackChunkName: "bacs" */ '../views/popup/Bacs.vue'),
  },
  {
    path: '/bpn',
    name: 'bpn',
    component: () => import(/* webpackChunkName: "bpn" */ '../views/popup/Bpn.vue'),
  },
  {
    path: '/bps',
    name: 'bps',
    component: () => import(/* webpackChunkName: "bps" */ '../views/popup/Bps.vue'),
  },
  {
    path: '/bsm',
    name: 'bsm',
    component: () => import(/* webpackChunkName: "bsm" */ '../views/popup/Bsm.vue'),
  },
  {
    path: '/caserne',
    name: 'caserne',
    component: () => import(/* webpackChunkName: "caserne" */ '../views/popup/Caserne.vue'),
  },
  {
    path: '/cigognes',
    name: 'cigognes',
    component: () => import(/* webpackChunkName: "cigognes" */ '../views/popup/Cigognes.vue'),
  },
  {
    path: '/depot',
    name: 'depot',
    component: () => import(/* webpackChunkName: "depot" */ '../views/popup/Depot.vue'),
  },
  {
    path: '/ecole',
    name: 'ecole',
    component: () => import(/* webpackChunkName: "ecole" */ '../views/popup/Ecole.vue'),
  },
  {
    path: '/grn',
    name: 'grn',
    component: () => import(/* webpackChunkName: "grn" */ '../views/popup/Grn.vue'),
  },
  {
    path: '/grs',
    name: 'grs',
    component: () => import(/* webpackChunkName: "grs" */ '../views/popup/Grs.vue'),
  },
  {
    path: '/vgo',
    name: 'vgo',
    component: () => import(/* webpackChunkName: "vgo" */ '../views/popup/Vgo.vue'),
  },
  {
    path: '/laboratoire',
    name: 'laboratoire',
    component: () => import(/* webpackChunkName: "laboratoire" */ '../views/popup/Laboratoire.vue'),
  },
  {
    path: '/etapes',
    name: 'etapes',
    component: () => import(/* webpackChunkName: "etapes" */ '../views/popup/Etapes.vue'),
  },
  {
    path: '/menhir',
    name: 'menhir',
    component: () => import(/* webpackChunkName: "menhir" */ '../views/popup/Menhir.vue'),
  },
  {
    path: '/metier-cycliste',
    name: 'metier-cycliste',
    component: () => import(/* webpackChunkName: "metier-cycliste" */ '../views/popup/Metier-cycliste.vue'),
    children: [
      {
        path: 'securite',
        name: 'securite',
        component: () => import(/* webpackChunkName: "securite" */ '../views/popup/metier/Securite.vue'),
      },
      {
        path: 'inspection',
        name: 'inspection',
        component: () => import(/* webpackChunkName: "inspection" */ '../views/popup/metier/Inspection.vue'),
      },
      {
        path: 'qse',
        name: 'qse',
        component: () => import(/* webpackChunkName: "qse" */ '../views/popup/metier/Qse.vue'),
      },
      {
        path: 'environnement',
        name: 'environnement',
        component: () => import(/* webpackChunkName: "environnement" */ '../views/popup/metier/Environnement.vue'),
      },
      {
        path: 'securite-industrielle',
        name: 'securite-industrielle',
        component: () => import(/* webpackChunkName: "securite-industrielle" */ '../views/popup/metier/Securite-industrielle.vue'),
      },
      {
        path: 'production',
        name: 'production',
        component: () => import(/* webpackChunkName: "production" */ '../views/popup/metier/Production.vue'),
      },
      {
        path: 'expedition-maritime',
        name: 'expedition-maritime',
        component: () => import(/* webpackChunkName: "expedition-maritime" */ '../views/popup/metier/Expedition-maritime.vue'),
      },
      {
        path: 'expedition-terrestre',
        name: 'expedition-terrestre',
        component: () => import(/* webpackChunkName: "expedition-terrestre" */ '../views/popup/metier/Expedition-terrestre.vue'),
      },
      {
        path: 'vern',
        name: 'vern',
        component: () => import(/* webpackChunkName: "vern" */ '../views/popup/metier/Vern.vue'),
      },
      {
        path: 'arrets-et-gros-travaux',
        name: 'arrets-et-gros-travaux',
        component: () => import(/* webpackChunkName: "arrets-et-gros-travaux" */ '../views/popup/metier/Arrets-et-gros-travaux.vue'),
      },
      {
        path: 'electricite',
        name: 'electricite',
        component: () => import(/* webpackChunkName: "electricite" */ '../views/popup/metier/Electricite.vue'),
      },
      {
        path: 'mecanique',
        name: 'mecanique',
        component: () => import(/* webpackChunkName: "mecanique" */ '../views/popup/metier/Mecanique.vue'),
      },
      {
        path: 'maintenance-courante',
        name: 'maintenance-courante',
        component: () => import(/* webpackChunkName: "maintenance-courante" */ '../views/popup/metier/Maintenance-courante.vue'),
      },
      {
        path: 'disponibilite-et-methodes-generales',
        name: 'disponibilite-et-methodes-generales',
        component: () => import(/* webpackChunkName: "disponibilite-et-methodes-generales" */ '../views/popup/metier/Disponibilite-et-methodes-generales.vue'),
      },
      {
        path: 'systemes-informatique-et-conduite',
        name: 'systemes-informatique-et-conduite',
        component: () => import(/* webpackChunkName: "systemes-informatique-et-conduite" */ '../views/popup/metier/Systemes-informatique-et-conduite.vue'),
      },
    ],
  },
  {
    path: '/metier-laborantin',
    name: 'metier-laborantin',
    component: () => import(/* webpackChunkName: "metier-laborantin" */ '../views/popup/Metier-laborantin.vue'),
    children: [
      {
        path: 'controle-de-performance',
        name: 'controle-de-performance',
        component: () => import(/* webpackChunkName: "controle-de-performance" */ '../views/popup/metier/Controle-de-performance.vue'),
      },
      {
        path: 'comptabilite-controle-de-gestion',
        name: 'comptabilite-controle-de-gestion',
        component: () => import(/* webpackChunkName: "comptabilite-controle-de-gestion" */ '../views/popup/metier/Comptabilite-controle-de-gestion.vue'),
      },
      {
        path: 'ordonnancement',
        name: 'ordonnancement',
        component: () => import(/* webpackChunkName: "ordonnancement" */ '../views/popup/metier/Ordonnancement.vue'),
      },
      {
        path: 'medecine-du-travail',
        name: 'medecine-du-travail',
        component: () => import(/* webpackChunkName: "medecine-du-travail" */ '../views/popup/metier/Medecine-du-travail.vue'),
      },
      {
        path: 'rh',
        name: 'rh',
        component: () => import(/* webpackChunkName: "rh" */ '../views/popup/metier/Rh.vue'),
      },
      {
        path: 'communication',
        name: 'communication',
        component: () => import(/* webpackChunkName: "communication" */ '../views/popup/metier/Communication.vue'),
      },
      {
        path: 'achats',
        name: 'achats',
        component: () => import(/* webpackChunkName: "achats" */ '../views/popup/metier/Achats.vue'),
      },
      {
        path: 'cadre-de-vie',
        name: 'cadre-de-vie',
        component: () => import(/* webpackChunkName: "cadre-de-vie" */ '../views/popup/metier/Cadre-de-vie.vue'),
      },
      {
        path: 'procedes',
        name: 'procedes',
        component: () => import(/* webpackChunkName: "procedes" */ '../views/popup/metier/Procedes.vue'),
      },
      {
        path: 'laboratoire',
        name: 'metier-laboratoire',
        component: () => import(/* webpackChunkName: "laboratoire" */ '../views/popup/metier/Laboratoire.vue'),
      },
    ],
  },
  {
    path: '/oleoduc',
    name: 'oleoduc',
    component: () => import(/* webpackChunkName: "oleoduc" */ '../views/popup/Oleoduc.vue'),
  },
  {
    path: '/smr',
    name: 'smr',
    component: () => import(/* webpackChunkName: "smr" */ '../views/popup/Smr.vue'),
  },
  {
    path: '/sonometre',
    name: 'sonometre',
    component: () => import(/* webpackChunkName: "sonometre" */ '../views/popup/Sonometre.vue'),
  },
  {
    path: '/torches',
    name: 'torches',
    component: () => import(/* webpackChunkName: "torches" */ '../views/popup/Torches.vue'),
  },
  {
    path: '/traitement',
    name: 'traitement',
    component: () => import(/* webpackChunkName: "traitement" */ '../views/popup/Traitement.vue'),
  },
  {
    path: '/tri',
    name: 'tri',
    component: () => import(/* webpackChunkName: "tri" */ '../views/popup/Tri.vue'),
  },
  {
    path: '/voie',
    name: 'voie',
    component: () => import(/* webpackChunkName: "voie" */ '../views/popup/Voie.vue'),
  },
  {
    path: '/melangeuse',
    name: 'melangeuse',
    component: () => import(/* webpackChunkName: "melangeuse" */ '../views/popup/Melangeuse.vue'),
  }



  ]

  const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
  })

  export default router
